/* eslint
import/prefer-default-export: "off",
no-restricted-syntax: "off" */

/**
 * Converts a string literal of CSVs to an object.
 * @param string[String]
 * @returns {Object}
 */
export const csvStringToObject = string => {
  // Sometimes, when using the editor within Github, a Carriage Return character: \r
  // will be added in addition to a newline character: \n
  // So we remove extra whitespace with `trim` and global remove any carriage returns with `replace`
  // Before splitting the rows by the newline character \n
  const rows = string.trim().replace(/\r/g, '').split('\n')
  const headers = rows.shift().split(',')

  return rows.map(row => {
    const data = row.trim().split(',')
    return Object.fromEntries(
      headers.map((header, i) => (
        [header, data[i]]
      )),
    )
  })
}
